import React, { useState, useEffect } from "react";
import { Grid, Box, Tab, Tabs, TabPanel, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setSelectedIndex } from "../../features/selectedIndexSlice";
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import HeaderInfo from "./HeaderInfo";
import DashboardTabs from "./DashboardTabs";
import ToolsUpdateNotification from "../../tools/ToolsUpdateNotification";
import toolsDashboardApi from "../../tools/toolsDashboardApi";

const NAME = "Dashboard";

export default function Dashboard() {
    const { getDashboardItemsSummary } = toolsDashboardApi();
    const dispatch = useDispatch();
    const gpc = useSelector((state) => state.gpc.value);
    const selectedDataModel = useSelector((state) => state.selectedDataModel.value);
    const [classTotal, setClassTotal] = useState(0);
    const [itemsTotal, setItemsTotal] = useState(0);
    //const {open: openUpdateNotification} = ToolsUpdateNotification();

    useEffect(() => {
        dispatch(setSelectedIndex(NAME));
        getDashboardItemsSummary().then((data) => {
            console.log(data)
            if(!data.error) {
                setClassTotal(data.payload.classification.length);
                setItemsTotal((data.payload.total.count))
            }
        }
        );
        //openUpdateNotification("una descripcion", 5)
    }, []);

    

    const mainBoxStyle = {
        height: "calc(100% - 75px)",
        width: '100%',
        overflowY: 'scroll',
        paddingTop: '50px'
    }
    

    return  <Box name="Dashboard" style={mainBoxStyle}>
                <Grid container direction="column" rowSpacing={4}>
                    <Grid item container xs={12} >
                        <Grid item xs={0.5}></Grid>
                        <Grid item className="totalClass" xs={3.3} >
                            <HeaderInfo icon={<FolderCopyOutlinedIcon style={{color: "#E16C43"}} />} name={"Total Classifications"} value={classTotal} />
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item className="notClass" xs={3.3} >
                            <HeaderInfo icon={<FolderOffOutlinedIcon style={{color: "#E16C43"}} />} name={"Total Products"} value={itemsTotal} />
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={3.3} >
                            <HeaderInfo icon={<FolderOpenOutlinedIcon style={{color: "#E16C43"}} />} name={"Registered"} value={"4"} total={"67"} />
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                    </Grid>
                    <Grid item container padding={5}>
                        <DashboardTabs />
                    </Grid>
                </Grid>
            </Box>;
}
