import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Box, Typography, Grid, Tooltip } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';
import toolsDashboardApi from "../../../tools/toolsDashboardApi";

const BorderLinearProgress = styled(LinearProgress)(({ theme, warning }) => ({
    height: 30,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: warning ? '#f2a083' : '#CCF3D7',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor:  warning ? '#e16c43' : '#3AAE62',
    },
  }));

export default function MappingStatus() {
    const { getDashboardMappingSummary } = toolsDashboardApi();
    const selectedDataModel = useSelector((state) => state.selectedDataModel.value);
    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(rows !== undefined) {
            setLoading(false);
        }
    }, [rows])

    const processMappingSummaryData = (rawData) => {
        
        let processed = [];
        let inserted = 0;

        rawData.map((brick) => {
            console.log(brick)
            if(brick.brick !== undefined && brick.brick !== null) {
                let unmapped = brick.unmapped >= 0 ? brick.unmapped : 0;
                let mandatoryUnmapped = brick.mandatoryUnmapped >= 0 ? brick.mandatoryUnmapped : 0;
                let mandatoryGpcUnmapped = brick.mandatoryGpcUnmapped >= 0 ? brick.mandatoryGpcUnmapped : 0;
                processed.push({id: inserted, classification: brick.brick + " - " + brick.brickName, filled: brick.mapped, total: brick.mapped + unmapped, mandatoryCoreFilled: brick.mandatoryMapped, mandatoryCoreTotal: brick.mandatoryMapped + mandatoryUnmapped, mandatoryClassifiedFilled: brick.mandatoryGpcMapped, mandatoryClassifiedTotal: brick.mandatoryGpcMapped + mandatoryGpcUnmapped});
                inserted++;
            }
        })
        console.log(processed)
        return processed;
    }

    useEffect(() => {
        if(selectedDataModel !== undefined && selectedDataModel.type !== undefined && selectedDataModel.version !== undefined) {
            getDashboardMappingSummary(selectedDataModel.type, selectedDataModel.version).then((data) => {
                console.log(data)
                if(!data.error) {
                    let processedRows = processMappingSummaryData(data.payload);
                    setRows(processedRows);
                    setTotal(processedRows.length);
                }
            })
        }
    }, [selectedDataModel])

    const renderProgressBar = (value, total, warning) => {
        let percentage = (100*value)/total;
        return  <Grid container style={{width: '100%'}} columnSpacing={1} alignContent={'center'} justifyContent={'center'}>
                    <Grid item xs={10}>
                        <BorderLinearProgress
                            variant="determinate"
                            size="xl"
                            value={percentage}
                            warning={warning && value < total}
                        />
                        <Typography 
                            style={{
                                color:'#FFFFFF',
                                position: 'absolute',
                                transform: "translate(50%, -110%)",
                            }}
                        >
                            {value}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{color:'#3888C8'}}>
                            {total}
                        </Typography>
                    </Grid>
                </Grid> 
        
                
    }
    // Si los mandatories no estan al 100% que se ponga naranja
    // Si uno de los dos esta en naranja ponemos icono de warning delante del nombre
    const columns = [
        {
            field: 'warning',
            headerName: 'warning',
            align: 'left',
            minWidth: 50,
            width: 50,
            renderHeader: () => (
                <Typography variant="selected"></Typography>
            ),
            renderCell: (params) => (
                params.row.mandatoryCoreFilled < params.row.mandatoryCoreTotal || params.row.mandatoryClassifiedFilled < params.row.mandatoryClassifiedTotal ? 
                    <Typography variant="orange"><Tooltip title="Mandatory Field"><WarningAmberIcon /></Tooltip></Typography> 
                : 
                    ''
            )
        },
        {
            field: 'classification',
            headerName: 'Classification',
            align: 'left',
            minWidth: 150,
            width: 400,
            renderHeader: () => (
                <Typography variant="selected">Classification ({total})</Typography>
            ),
        },
        {
            field: 'allAttributes',
            headerName: 'All Attributes',
            align: 'left',
            minWidth: 150,
            width: 400,
            renderCell: (params) => renderProgressBar(params.row.filled, params.row.total, false),
            renderHeader: () => (
                <Typography variant="selected">All Attributes</Typography>
            ),
        },
        {
            field: 'mandatoryCore',
            headerName: 'Mandatory Core',
            align: 'left',
            minWidth: 150,
            width: 400,
            renderCell: (params) => renderProgressBar(params.row.mandatoryCoreFilled, params.row.mandatoryCoreTotal, true)
            ,
            renderHeader: () => (
                <Typography variant="selected">Mandatory Core</Typography>
            ),
        },
        {
            field: 'mandatoryClassified',
            headerName: 'Mandatory Classified',
            align: 'left',
            minWidth: 150,
            width: 400,
            renderCell: (params) => renderProgressBar(params.row.mandatoryClassifiedFilled, params.row.mandatoryClassifiedTotal, true)
            ,
            renderHeader: () => (
                <Typography variant="selected">Mandatory Classified</Typography>
            ),
        },
    ]

    return  <Box sx={{ height: 400, width: '100%' }}>
                <DataGridPro
                    loading={loading}
                    disableColumnMenu
                    hideFooterPagination
                    hideFooter
                    rows={rows === undefined ? [] : rows}
                    style={{height: '100%'}}
                    columns={columns}
                    disableSelectionOnClick
                />
            </Box>
}